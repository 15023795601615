module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"vpilip","accessToken":"MC5YcjVkMFJFQUFHRm8zZ0lO.axzvv73vv71877-9O--_vTnvv704Mh5M77-977-977-9eO-_ve-_ve-_ve-_ve-_ve-_vTQ3YXUX77-977-9fQ","previews":false,"omitPrismicScript":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W36FRMP","includeInDevelopment":true,"gtmAuth":"xDGAs7uKntNF5bX-58s9tQ","gtmPreview":"env-1","dataLayerName":"dataLayer"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
